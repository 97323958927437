var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { staticClass: "d-flex flex-row" }, [
    _c(
      "div",
      {
        class: _vm.confirmButton
          ? "col-md-10 no-left-padd"
          : "col-md-12 no-side-padd",
      },
      [
        _c("multi-select", {
          attrs: {
            value: _vm.selectedJurisdiction,
            "track-by": "id",
            label: "state_province_region",
            placeholder: "Select your company's home state",
            options: _vm.jurisdictions,
          },
          on: {
            select: function ($event) {
              return _vm.jurisdictionSelected($event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }