<template>
  <form class="d-flex flex-row">
    <div :class="confirmButton ? 'col-md-10 no-left-padd' : 'col-md-12 no-side-padd'">
      <multi-select
        :value="selectedJurisdiction"
        track-by="id"
        label="state_province_region"
        placeholder="Select your company's home state"
        :options="jurisdictions"
        @select="jurisdictionSelected($event)"
      />
    </div>
  </form>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import MultiSelect from 'vue-multiselect'

  export default {
    name: 'CompanyDomesticRegistrationForm',
    components: {
      MultiSelect,
    },
    props: {
      company: Object,
      loadProducts: Boolean,
      confirmButton: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedJurisdiction: {},
      }
    },
    computed: {
      ...mapGetters('jurisdictions', [
        'jurisdictions', 'findById',
      ]),
      ...mapGetters('companies', [
        'domesticRegistration',
        'checkoutDomesticJurisdiction',
      ]),

      jurisdiction() {
        return this?.domesticRegistration?.jurisdiction
          || this?.checkoutDomesticJurisdiction
          || null
      },
    },
    async mounted() {
      this.$store.dispatch('jurisdictions/load')
      this.selectedJurisdiction = this.jurisdiction
    },
    methods: {
      ...mapActions('companies', ['updateDomesticRegistrationJurisdiction']),

      jurisdictionSelected(jurisdiction) {
        this.selectedJurisdiction = jurisdiction

        const oldHomeState = this?.domesticRegistration?.jurisdiction
          || this?.checkoutDomesticJurisdiction
          || null

        this.$emit('changed', oldHomeState, this.selectedJurisdiction)
      },
    },
  }
</script>
<style lang="scss">
  .no-left-padd {
    padding-left: 0 !important;
  }
  .no-side-padd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
</style>
